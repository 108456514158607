export const WORDS = [
  'timug',
  'bulaq',
  'sakab',
  'sobra',
  'oreta',
  'tutuk',
  'sibad',
  'tubaq',
  'bumba',
  'tulud',
  'utang',
  'naman',
  'abaka',
  'bukal',
  'broca',
  'pipit',
  'kinis',
  'butit',
  'saluq',
  'petsa',
  'durun',
  'giliq',
  'suyaq',
  'lukaq',
  'balat',
  'lulaq',
  'liwas',
  'nasiq',
  'picil',
  'tukil',
  'kanuq',
  'sibul',
  'pupul',
  'sesyu',
  'nunuq',
  'sulud',
  'libut',
  'dikut',
  'samba',
  'damas',
  'sabul',
  'kilik',
  'dinat',
  'bawas',
  'labak',
  'wawaq',
  'oreni',
  'gamus',
  'tilis',
  'talaq',
  'tikal',
  'banal',
  'pulut',
  'palis',
  'nugut',
  'timid',
  'banus',
  'lango',
  'parul',
  'pisiq',
  'pulis',
  'aklat',
  'biniq',
  'klasi',
  'pilik',
  'tasan',
  'qinum',
  'balas',
  'tagun',
  'paliq',
  'kawag',
  'butuq',
  'takap',
  'kurus',
  'saklo',
  'kunaq',
  'pulut',
  'tutul',
  'arnis',
  'pekat',
  'dalan',
  'sipag',
  'akmul',
  'kanal',
  'purus',
  'dimla',
  'tibaq',
  'lulut',
  'sidja',
  'igpaq',
  'pilyu',
  'tulin',
  'selyu',
  'ablas',
  'runut',
  'bilad',
  'lunis',
  'laram',
  'tiyan',
  'merli',
  'ambun',
  'palub',
  'gaslo',
  'libru',
  'sakla',
  'lumot',
  'pilan',
  'dukuq',
  'sopan',
  'putut',
  'tanam',
  'butil',
  'igpit',
  'dukit',
  'takal',
  'pulsu',
  'kuneu',
  'manuk',
  'salol',
  'bulig',
  'tuliq',
  'kuwat',
  'taram',
  'tubuq',
  'tabug',
  'bakal',
  'lawas',
  'bayat',
  'eneru',
  'sable',
  'turan',
  'bagun',
  'lulam',
  'lapis',
  'kukuq',
  'pakuq',
  'bilis',
  'agyuq',
  'bistu',
  'balag',
  'litru',
  'sabat',
  'dapuk',
  'adjar',
  'kutis',
  'muriq',
  'dimla',
  'salas',
  'bisaq',
  'rimas',
  'bakal',
  'kirut',
  'porma',
  'dapot',
  'puluq',
  'kasal',
  'pilak',
  'palid',
  'bakse',
  'baguk',
  'kabag',
  'indam',
  'lunus',
  'mamaq',
  'sabit',
  'labiq',
  'puguq',
  'duray',
  'atake',
  'sunuq',
  'pulpi',
  'saliq',
  'bigaq',
  'munta',
  'tugak',
  'lamat',
  'sensi',
  'pusuq',
  'lapad',
  'sukad',
  'dalas',
  'kesaq',
  'karen',
  'lunas',
  'sosoq',
  'mundu',
  'busal',
  'kuyad',
  'balai',
  'pitik',
  'lakto',
  'patad',
  'gabun',
  'atras',
  'syano',
  'aslat',
  'uriya',
  'burat',
  'maneu',
  'dapug',
  'lakad',
  'pakde',
  'loren',
  'iskuq',
  'pusan',
  'arung',
  'labas',
  'hilut',
  'kapit',
  'bikan',
  'pusta',
  'wakas',
  'kapad',
  'datuq',
  'barag',
  'kimut',
  'mayul',
  'pulai',
  'sopas',
  'dugpa',
  'agtal',
  'napun',
  'babuq',
  'sulit',
  'sakul',
  'gapas',
  'babiq',
  'qisip',
  'kabli',
  'kulul',
  'pista',
  'alpaq',
  'puniq',
  'ugnay',
  'gilid',
  'salaq',
  'lisik',
  'adwaq',
  'tagak',
  'amanu',
  'agkat',
  'pulaq',
  'qilig',
  'labit',
  'sayad',
  'burul',
  'latik',
  'tatap',
  'qatik',
  'parti',
  'dapot',
  'dilaq',
  'abisu',
  'syoki',
  'karug',
  'antak',
  'sasag',
  'sapak',
  'asang',
  'perla',
  'papag',
  'alili',
  'lubid',
  'tapaq',
  'tupad',
  'agyap',
  'ampon',
  'salun',
  'linis',
  'subuk',
  'suput',
  'liguq',
  'nanaq',
  'asawa',
  'timan',
  'bante',
  'dayaq',
  'yawig',
  'gisan',
  'abyas',
  'rabit',
  'yariq',
  'bulag',
  'tarat',
  'tagal',
  'lulud',
  'tunud',
  'dalas',
  'pasku',
  'tusuk',
  'bisyu',
  'tabun',
  'pasaq',
  'pusad',
  'bengi',
  'busal',
  'kantu',
  'buguk',
  'talan',
  'pugad',
  'ambag',
  'plays',
  'nanan',
  'dapaq',
  'kabud',
  'sabik',
  'inata',
  'sikan',
  'samat',
  'gawaq',
  'iglip',
  'monte',
  'baril',
  'pitik',
  'kural',
  'sikad',
  'siguk',
  'kayag',
  'katun',
  'likas',
  'misis',
  'susuq',
  'almaq',
  'layit',
  'sakag',
  'dagul',
  'labuk',
  'manik',
  'qisis',
  'bakle',
  'balat',
  'bosis',
  'ikami',
  'suluq',
  'kupal',
  'qurud',
  'aslam',
  'lalam',
  'murit',
  'lumud',
  'atcaq',
  'bakud',
  'nyang',
  'kimse',
  'pilon',
  'dakap',
  'kakaq',
  'palyu',
  'oneng',
  'larga',
  'dagul',
  'karga',
  'punuq',
  'suman',
  'tariq',
  'luwid',
  'ranun',
  'kendi',
  'bilas',
  'samut',
  'duluk',
  'ulnan',
  'pagal',
  'tibag',
  'bandi',
  'kampu',
  'lagaq',
  'sukiq',
  'sapal',
  'lagad',
  'sukle',
  'batik',
  'silip',
  'laruq',
  'bulus',
  'sukaq',
  'balik',
  'asung',
  'putat',
  'palut',
  'abono',
  'laman',
  'absiq',
  'biyas',
  'diluq',
  'bunut',
  'luluq',
  'singo',
  'kalyu',
  'takut',
  'bawiq',
  'ligaq',
  'mismo',
  'siper',
  'sapaq',
  'tabaq',
  'kapit',
  'wagas',
  'abala',
  'guluk',
  'ester',
  'torno',
  'duman',
  'laban',
  'gayak',
  'kirat',
  'baluq',
  'gayad',
  'panga',
  'turuq',
  'sinta',
  'sulat',
  'barug',
  'lukut',
  'payat',
  'pigil',
  'lasun',
  'uling',
  'patan',
  'absiq',
  'nasaq',
  'gubat',
  'duruq',
  'bugin',
  'tikta',
  'suhul',
  'gamat',
  'paras',
  'basaq',
  'daraq',
  'lande',
  'takde',
  'yatuq',
  'balas',
  'tapun',
  'panas',
  'samas',
  'laguq',
  'balaq',
  'baryu',
  'sigaq',
  'lubas',
  'danup',
  'silab',
  'samak',
  'kulas',
  'ampil',
  'plaka',
  'ditak',
  'basiq',
  'kapis',
  'kuran',
  'balag',
  'lupit',
  'bulak',
  'dakal',
  'patak',
  'tapak',
  'acata',
  'ditak',
  'maski',
  'kasat',
  'yapus',
  'sakal',
  'ukyat',
  'patiq',
  'balid',
  'tuksu',
  'lutas',
  'sirup',
  'takid',
  'tuyuq',
  'natiq',
  'butul',
  'buluq',
  'karin',
  'aunus',
  'degla',
  'menus',
  'suguq',
  'talik',
  'dinat',
  'laraq',
  'dayap',
  'abril',
  'kayas',
  'pusit',
  'gagaq',
  'kitig',
  'turil',
  'pasiq',
  'labiq',
  'tinda',
  'gansa',
  'sagin',
  'luyus',
  'utang',
  'pondu',
  'patuq',
  'situq',
  'bagin',
  'damiq',
  'awang',
  'katri',
  'putul',
  'papaq',
  'yemuk',
  'danum',
  'muton',
  'gigit',
  'tisaq',
  'dalig',
  'sukab',
  'lubak',
  'ratun',
  'tuluk',
  'samaq',
  'tinap',
  'balen',
  'parit',
  'sabal',
  'paliq',
  'durut',
  'tamad',
  'disul',
  'balut',
  'bukud',
  'tiran',
  'gamit',
  'sawaq',
  'salat',
  'kapal',
  'tukud',
  'kayan',
  'banwa',
  'lipat',
  'punta',
  'altar',
  'diliq',
  'sabun',
  'lanat',
  'pinta',
  'sasaq',
  'kutud',
  'labug',
  'kaluq',
  'lapit',
  'lanam',
  'marso',
  'multa',
  'plato',
  'inyad',
  'gawiq',
  'sarsa',
  'arina',
  'kulam',
  'sobri',
  'puguq',
  'kabit',
  'sadja',
  'gatas',
  'rapal',
  'bakwe',
  'anyaq',
  'salud',
  'guyud',
  'reyna',
  'bulyo',
  'tigim',
  'salut',
  'sukal',
  'mimin',
  'pariq',
  'bulad',
  'taruk',
  'saluq',
  'latiq',
  'lutuq',
  'bilug',
  'bagaq',
  'misan',
  'pulis',
  'labul',
  'bawal',
  'tanga',
  'manga',
  'ganap',
  'kayaq',
  'banda',
  'karat',
  'qimuq',
  'darak',
  'muliq',
  'lumbe',
  'punla',
  'atduq',
  'bugaq',
  'dalit',
  'biruq',
  'tamas',
  'bantu',
  'batal',
  'tabal',
  'baruq',
  'kilus',
  'tilus',
  'lugaq',
  'sampa',
  'salit',
  'danum',
  'tipak',
  'kurap',
  'belen',
  'lider',
  'sayaq',
  'karta',
  'rilis',
  'taliq',
  'dilat',
  'selan',
  'lulun',
  'kupas',
  'kabaq',
  'babaq',
  'akbak',
  'cupon',
  'tipid',
  'gapis',
  'pakit',
  'sulut',
  'dalum',
  'lawun',
  'talip',
  'tulus',
  'tipun',
  'natad',
  'apqap',
  'bunsu',
  'puqin',
  'ninap',
  'sulip',
  'babai',
  'kimuq',
  'tayiq',
  'lutuq',
  'paldu',
  'bayag',
  'banda',
  'angga',
  'kanyu',
  'puyat',
  'likut',
  'derit',
  'ambus',
  'dayat',
  'gatal',
  'bayad',
  'takas',
  'tapil',
  'suluk',
  'lapat',
  'pusaq',
  'pugon',
  'bulas',
  'pipiq',
  'pekas',
  'sanga',
  'bitri',
  'damut',
  'sapaq',
  'lalbe',
  'kulut',
  'albul',
  'uling',
  'kakak',
  'sisiq',
  'gintu',
  'sakup',
  'kabaq',
  'quyab',
  'basaq',
  'susiq',
  'litid',
  'palad',
  'mulal',
  'lawut',
  'oklak',
  'byung',
  'sanip',
  'ternu',
  'dapat',
  'layag',
  'dagis',
  'tabla',
  'gapus',
  'sinup',
  'imbes',
  'pisan',
  'pilit',
  'bunga',
  'patag',
  'tyune',
  'duraq',
  'sugat',
  'kalis',
  'pader',
  'piris',
  'bukas',
  'nabuq',
  'ligid',
  'angin',
  'sakit',
  'gulut',
  'kupiq',
  'asbuk',
  'salak',
  'samul',
  'soraq',
  'gigut',
  'baliq',
  'subuq',
  'pesus',
  'sukul',
  'gulis',
  'nandu',
  'dapat',
  'bulan',
  'ngeni',
  'lasuq',
  'tulid',
  'yapsa',
  'tinta',
  'tiris',
  'babaq',
  'impis',
  'kulap',
  'taruq',
  'libad',
  'pisuq',
  'papil',
  'balut',
  'bagyu',
  'sipit',
  'asyag',
  'uldug',
  'tagan',
  'tubud',
  'pawas',
  'bukuq',
  'bagut',
  'wanan',
  'kapri',
  'yakap',
  'balok',
  'katat',
  'byuda',
  'eljin',
  'burak',
  'lingo',
  'silak',
  'kulti',
  'marka',
  'atcan',
  'lugud',
  'ambuq',
  'subiq',
  'pande',
  'ungut',
  'tuyaq',
  'bitin',
  'inete',
  'lasak',
  'lugal',
  'buren',
  'terak',
  'kacap',
  'tarat',
  'tulak',
  'purga',
  'asyas',
  'unyat',
  'ingat',
  'tawad',
  'mamaq',
  'pesti',
  'bitis',
  'aslag',
  'sugal',
  'pasya',
  'sindi',
  'yariq',
  'pigaq',
  'lipak',
  'andas',
  'banyu',
  'berde',
  'bitki',
  'lilyu',
  'kalat',
  'salta',
  'lukas',
  'kopas',
  'bruha',
  'gabak',
  'keraq',
  'kaung',
  'pisuk',
  'lukub',
  'maliq',
  'bukul',
  'lakal',
  'ligat',
  'dapug',
  'webis',
  'maniq',
  'salat',
  'tilaw',
  'milun',
  'talba',
  'bakit',
  'matas',
  'danup',
  'liyas',
  'tulaq',
  'andam',
  'dayuq',
  'bakas',
  'buluk',
]