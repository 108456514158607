export const WORDLE_TITLE = 'AH MAKANYAN?'

export const WIN_MESSAGES = ['Ikwa me!', 'Awa ne!', 'Stu ya kanyan!']
export const GAME_COPIED_MESSAGE = 'Game copied to clipboard'
export const ABOUT_GAME_MESSAGE = 'Nanu ya ini?!  '
export const NOT_ENOUGH_LETTERS_MESSAGE = 'Kulang la ring letra!'
export const WORD_NOT_FOUND_MESSAGE = 'Eke balu ing amanung yan'
export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `The word was ${solution}`
export const ENTER_TEXT = 'Enter'
export const DELETE_TEXT = 'Delete'
export const STATISTICS_TITLE = 'Statistics'
export const GUESS_DISTRIBUTION_TEXT = 'Guess Distribution'
export const NEW_WORD_TEXT = 'New word in'
export const SHARE_TEXT = 'Share'
export const TOTAL_TRIES_TEXT = 'Total tries'
export const SUCCESS_RATE_TEXT = 'Success rate'
export const CURRENT_STREAK_TEXT = 'Current streak'
export const BEST_STREAK_TEXT = 'Best streak'
